<template>
  <div></div>
</template>

<script>
  export default {
    name: "project",
    data(){
      return {

      }
    },
    created() {
      this.$emit('setIndex',[1,0])
    }
  }
</script>

<style scoped lang="scss">

</style>